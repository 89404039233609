import React, { memo, useEffect, useRef, useState } from 'react';

import './BetContainer.scss';
import { resizeElement } from '../../utils/Utils';
import SportsBlock from "../../components/SportsBlock/SportsBlock";
import BetSlipBlock from "../../components/BetSlipBlock/BetSlipBlock";
import HistoryPupUp from "../../components/BetSlipBlock/HistoryPopUp/HistoryPopUp";
import CashOutPopUp from "../../components/BetSlipBlock/CashOutPopUp/CashOutPopUp";

function BetContainer() {
  const [historyPupUpShown, setHistoryPupUpShown] = useState(false);
  const [cashOutPopUpShown, setCashOutPopUpShown] = useState(false);
  const [cashOutPopUpData, setCashOutPopUpData] = useState({});
  const elementRef = useRef(null);

  const showHistoryPopUp = () => {
    setHistoryPupUpShown(true);
  }

  const showCashOutPopUp = data => {
    setCashOutPopUpData(data)
    setCashOutPopUpShown(true);
  }

  const manageHistoryPopUpVisibility = () => {
    return historyPupUpShown ? <HistoryPupUp hideHistoryPopUp={(() => setHistoryPupUpShown(false))}/> : null;
  }

  const manageCashOutPopUpVisibility = () => {
    return cashOutPopUpShown
      ? <CashOutPopUp hideCashOutPopUp={() => setCashOutPopUpShown(false)} cashOutData={cashOutPopUpData}/>
      : null
  }

  useEffect(()=> {
    if(elementRef?.current){
      resizeElement(elementRef.current, 1280, 0.85)
      window.addEventListener('resize', () => resizeElement(elementRef.current, 1280, 0.85));
    }
  }, [elementRef] )

  return <div className='bet-container' ref={elementRef}>
    <SportsBlock/>
    <BetSlipBlock showHistoryPopUp={showHistoryPopUp} showCashOutPopUp={showCashOutPopUp} />
    {manageHistoryPopUpVisibility()}
    {manageCashOutPopUpVisibility()}
  </div>
}

export default memo(BetContainer);
