import React, { memo, useContext, useEffect, useState } from 'react';
import './MaxBet.scss';
import { AppContext } from '../../../App';
import { useTranslation } from 'react-i18next'
import { MAX_BET_DATA, PLACE_BETS_DATA, RE_CAPTCHA_DATA, RE_CAPTCHA_MAX_DATA } from '../../../services/RequestDatas';
import RequestManager from '../../../services/RequestManager';
import Utils from '../../../utils/Utils';

function MaxBet({ betType, totalCoef, clearBetsCallback, betTypes, currentCoef, systemSelectedIndex }) {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const betsData = context.betsData.get;
  const balance = context.balance.get;
  const socket = RequestManager.getInstance().webSocket;
  const currency = context.currency.get;
  const resultShowingSeconds = 2000;
  const minBetValue = context.partnerConfig.get['min_bet_stakes'][context.currency.get];
  const isTaxCase = context.partnerConfig.get['tax_integration_type'] === 1;

  const [ optionsEditing, setOptionsEditing ] = useState(false);
  const [ betValue, setBetValue ] = useState(minBetValue * 2);
  const [ warnings, setWarnings ] = useState([]);
  const [ betResult, setBetResult ] = useState(null);
  const [ placeProcess, setPlaceProcess ] = useState(false);

  const betOptions = Utils.getStorageData('betOptions') || [
      (minBetValue * 3).toFixed(2).replace(/\.00$/, ''),
      (minBetValue * 5).toFixed(2).replace(/\.00$/, ''),
      (minBetValue * 10).toFixed(2).replace(/\.00$/, '') ];

  const betOption1 = React.createRef();
  const betOption2 = React.createRef();
  const betOption3 = React.createRef();

  useEffect(() => {
    document.addEventListener('keydown', handleEnterSearch);

    return () => {
      document.removeEventListener('keydown', handleEnterSearch);
    }
  }, [ placeProcess, warnings ])

  useEffect(() => {
    if (context.currency.get) {
      setBetValue( context.partnerConfig.get['min_bet_stakes'][context.currency.get] * 2);
    }
  }, [context.currency.get])

  useEffect(() => {
    const failedBets = betsData.filter(item => item['status'] === 'failed');
    const thereAreDeletedEvents = failedBets.length > 0;
    const currentWarnings = warnings;
    const deletedEventsMessage = t('deleted events');
    const addMoreMessage = t('add more events');

    if (thereAreDeletedEvents) {
      if (warnings.indexOf(deletedEventsMessage) === -1) {
        currentWarnings.push(deletedEventsMessage);
      }
    } else if (betType === 'Multiple' && context.betsData.get.length < 2) {
      if (warnings.indexOf(addMoreMessage) === -1) {
        currentWarnings.push(addMoreMessage);
      }
    } else {
      currentWarnings.splice(currentWarnings.indexOf(deletedEventsMessage), 1);
      currentWarnings.splice(currentWarnings.indexOf(addMoreMessage), 1);
    }

    setWarnings([ ...currentWarnings ]);
  }, [ context.betsData.get, currentCoef, betType ])

  const sendRequest = betData => {
    socket.send(JSON.stringify(betData));
    socket.addEventListener(RequestManager.PLACE_BETS_EVENT, response => handlePlaceBet(response.detail), { once: true });
  }

  const sendPlaceBetRequest = (type, amount, bets, sysBet = 0) => {
    PLACE_BETS_DATA.params.type = type;
    PLACE_BETS_DATA.params.amount = amount;
    PLACE_BETS_DATA.params.bets = bets;
    PLACE_BETS_DATA.params.sys_bet = sysBet;
    PLACE_BETS_DATA.rid = Utils.getUniqNumber().toString();

    sendRequest(PLACE_BETS_DATA);
  }

  const checkPlaceBetIsEnabled = () => {
    let enabled = betValue && !warnings.length && context.userLoggedIn.get;
    enabled = betType === 'Multiple' ? enabled : true;

    if (betType === 'System') {
      enabled = !warnings.length && context.userLoggedIn.get && betValue && currentCoef && systemSelectedIndex && totalCoef;
    } else if (betType === 'Single') {
      enabled = !warnings.length && context.userLoggedIn.get && betsData.some(item => item.betValue)
    }

    if (placeProcess || context.userLoggedIn.get === false) {
      enabled = false;
    }

    return enabled
  }

  const handleEnterSearch = event => {
    const enabled = checkPlaceBetIsEnabled()
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      enabled && prepareToPlaceBets();
    }
  }

  const initSinglePlaceBetsRequest = () => {
    const betsData = context.betsData.get;
    const currentWarnings = [...warnings];

    let minBetCase = 0;
    let totalBetValue = 0;
    betsData.forEach(item => {
      if (item['betValue']) {
        if (item['betValue'] && Number(item['betValue']) < minBetValue) {
          minBetCase = true;
        }

        totalBetValue += Number(item['betValue'])
      } else {
        minBetCase = true;
      }
    });

    if (minBetCase || totalBetValue < minBetValue) {
      currentWarnings.push(`${ t('amount is less') } (${ minBetValue + ' ' + context.currency.get })`);
      setWarnings([ ...currentWarnings ]);
      setPlaceProcess(false);
      return;
    }

    if (totalBetValue > balance) {
      currentWarnings.push(t('insufficient balance'));
      setWarnings([ ...currentWarnings ]);
      setPlaceProcess(false);
      return;
    }

    betsData.forEach(item => {
      if (item['betValue'] && item['status'] === 'success') {
        sendPlaceBetRequest(1, item['betValue'], [ { event_id: item.id, price: item.price } ]);
      }
    })
  }

  const initMultiplePlaceBetsRequest = betValue => {
    const betsData = context.betsData.get;
    const bets = betsData.map(item => {
      if (item['status'] === 'success') {
        return { event_id: item.id, price: item.price };
      }
    });

    sendPlaceBetRequest(2, betValue, bets);
  }

  const initSystemPlaceBetsRequest = () => {
    const betsData = context.betsData.get;
    const bets = betsData.map(item => {
      if (item['status'] === 'success') {
        return { event_id: item.id, price: item.price };
      }
    });

    sendPlaceBetRequest(3, betValue * currentCoef, bets, systemSelectedIndex);
  }

  const prepareToPlaceBets = () => {
    if (warnings.length || placeProcess) {
      return;
    }

    setPlaceProcess(true);

    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      initReCaptchaScript();
    } else {
      placeBets();
    }
  }

  const initReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + context.reCaptchaData.get.siteKey;
    script.addEventListener('load', handleReCaptchaScriptLoaded);

    document.body.appendChild(script);
  }

  const handleReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, { action: 'do_bet' }).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token)
    })

    RE_CAPTCHA_DATA.rid = Utils.getUniqNumber().toString();

    socket.send(JSON.stringify(RE_CAPTCHA_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_EVENT, response => handleReCaptchaRequestResponse(response.detail), { once: true });
  }

  const handleReCaptchaRequestResponse = response => {
    // TODO needs to check response status.
    placeBets();
  }

  const placeBets = () => {
    if (betType === 'Single') {
      initSinglePlaceBetsRequest();
    } else if (betType === 'Multiple') {
      initMultiplePlaceBetsRequest(betValue);
    } else if (betType === 'System') {
      initSystemPlaceBetsRequest(betValue * currentCoef);
    }
  }

  const handlePlaceBet = ({ data }) => {
    const succeeded = data.result && data.result === 'OK';
    const currentActiveBets = context.activeBets.get;
    const resultText = data.result_text;

    if (succeeded) {
      setBetResult(t('bet accepted'));
      context.activeBets.set([ ...betsData, ...currentActiveBets ]);

      setTimeout(() => {
        setPlaceProcess(false);
        clearBetsCallback();

      }, resultShowingSeconds);
    } else {
      setBetResult(t(resultText));
      setPlaceProcess(false);
    }
  }

  const saveEditedOptions = () => {
    const newBetOptions = [
      (betOption1.current.value ? betOption1.current.value : betOptions[0]),
      (betOption2.current.value ? betOption2.current.value : betOptions[1]),
      (betOption3.current.value ? betOption3.current.value : betOptions[2])
    ];

    Utils.writeStorage('betOptions', newBetOptions)
    setOptionsEditing(!optionsEditing);
  }

  const checkMinValue = value => {
    const currentWarnings = warnings;
    const correctBetValue = value && value >= minBetValue;
    const betIsLessWarning = `${ t('amount is less') } (${ minBetValue + ' ' + context.currency.get })`;
    const warningIndex = currentWarnings.indexOf(betIsLessWarning);

    if (!correctBetValue && warningIndex === -1) {
      currentWarnings.push(betIsLessWarning);
    } else if (correctBetValue && warningIndex >= 0) {
      currentWarnings.splice(warningIndex, 1);
    }

    setWarnings([ ...currentWarnings ]);
  }

  const changeBetValue = value => {
    const pointIndex = String(value).indexOf('.');
    value = pointIndex !== -1 && String(value).length > pointIndex + 2 ? Number(Number(value).toFixed(2)) : value;

    if (typeof value === 'object') {
      Utils.correctBetInputValue(value);
      value = value.currentTarget.value;
    } else {
      checkMinValue(value);
      setBetValue(value);

      if (betType === 'Single') {
        const setSelectedValueOption = betsData.map(item => {
          return {
            ...item,
            betValue: value,
          }
        });

        context.betsData.set(setSelectedValueOption);
        Utils.writeStorage('betsData', setSelectedValueOption);
      }
    }
  }

  const prepareToGetMaxBetValue = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=' + context.reCaptchaData.get.siteKey;
      script.addEventListener('load', handleMaxBetReCaptchaScriptLoaded);

      document.body.appendChild(script);
    } else {
      getMaxBetValue();
    }

  }

  const handleMaxBetReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, { action: 'get_max_bet' }).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_MAX_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_MAX_EVENT, response => handleMaxReCaptchaRequestResponse(response.detail), { once: true });
  }

  const handleMaxReCaptchaRequestResponse = response => {
    // TODO needs to check response status.
    getMaxBetValue();
  }

  const getMaxBetValue = () => {
    const betItemIDs = context.betsData.get.map(item => item['id']);
    MAX_BET_DATA.params.type = parseInt(betTypes.indexOf(betType) + 1);
    MAX_BET_DATA.params.events = betItemIDs;

    socket.send(JSON.stringify(MAX_BET_DATA));
    socket.addEventListener(RequestManager.MAX_BET_EVENT, response => updateMaxBetValue(response.detail), { once: true });
  }

  const updateMaxBetValue = ({ data }) => {
    if (data && data.details?.amount) {
      changeBetValue(data.details.amount)
    }
  }

  const getPlaceBetBlock = () => {
    const enabled = checkPlaceBetIsEnabled()
    const className = enabled ? 'place-bet enabled' : 'place-bet';

    return <div className="place-bet-container">
      <div className={ className } onClick={ enabled ? prepareToPlaceBets : null }>
        { Utils.upperCase(t('place bet'), context.language.get) }
      </div>
      {
        warnings.map((warningMessage, index) => {
          return <div className="bet-warning" key={ index }>{ warningMessage }</div>
        })
      }
    </div>
  }

  const getStakeTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (betValue >= item['MinAmount'] && betValue <= item['MaxAmount'] && item['TaxType'] === 4)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getProfitTaxPercentage = taxedPossibleWin => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const taxStakePercentage = getStakeTaxPercentage();
    const calcTaxedPossibleWin = taxedPossibleWin - (betValue - betValue * taxStakePercentage / 100).toFixed(2);

    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (calcTaxedPossibleWin >= item['MinAmount'] && calcTaxedPossibleWin <= item['MaxAmount'] && item['TaxType'] === 1)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxStakeInfo = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxStakePercentage = getStakeTaxPercentage();
    const totalAmount = (betValue - betValue * taxStakePercentage / 100).toFixed(2);
    const taxedPossibleWin = getTaxedPossibleWin();
    const profitTaxPercentage = getProfitTaxPercentage(taxedPossibleWin);

    let taxedTotalProfit;
    if (profitTaxPercentage) {
      taxedTotalProfit = (taxedPossibleWin - totalAmount) * profitTaxPercentage / 100;
    }

    return taxStakePercentage ? <div className="tax-info">
      <div className="win-info">
        <p>{ t('tax amount') + '(' + taxStakePercentage + '%)' }</p>
        <p>{ betValue * taxStakePercentage / 100 }</p>
      </div>
      <div className="win-info">
        <p>{ t('final bet amount') } </p>
        <p>{ totalAmount }</p>
      </div>
      {
        taxedTotalProfit ? <div className="win-info">
          <p>Tax to be paid to RRA:</p>
          <p>{ taxedTotalProfit }</p>
        </div> : null
      }
      {
        taxedTotalProfit ? <div className="win-info">
          <p>{ t('final payout') }</p>
          <p>{ Number(taxedPossibleWin) - Number(taxedTotalProfit) }</p>
        </div> : null
      }
    </div> : null
  }

  const getWinTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const possibleWin = (totalCoef * betValue).toFixed(2);
    const selectedWinTaxRange = taxAmountRanges.filter(item => {
      return (possibleWin >= item['MinAmount'] && possibleWin <= item['MaxAmount'] && item['TaxType'] === 2)
    })[0];

    return selectedWinTaxRange ? selectedWinTaxRange['TaxPrecentage'] : null;
  }

  const getTaxWinInfo = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxWinPercentage = getWinTaxPercentage();
    const possibleWin = getTaxedPossibleWin();

    return taxWinPercentage ? <div className="tax-info">
      <div className="win-info">
        <p>{ t('tax amount') + '(' + taxWinPercentage + '%)' }</p>
        <p>{ (possibleWin * taxWinPercentage / 100).toFixed(2) }</p>
      </div>
      <div className="win-info">
        <p>{ t('final payout') }</p>
        <p>{ (possibleWin - (possibleWin * taxWinPercentage / 100)).toFixed(2) }</p>
      </div>
    </div> : null
  }

  const getTaxedPossibleWin = () => {
    const taxStakePercentage = getStakeTaxPercentage();
    return (totalCoef * (betValue - (betValue * taxStakePercentage / 100))).toFixed(2);
  }

  const getPotentialWinningBlock = () => {
    const potentialWin = isTaxCase ? getTaxedPossibleWin() : (totalCoef * betValue).toFixed(2);

    return warnings.length || betValue < minBetValue || (betType === 'System' && !currentCoef) || betType === 'Single'
      ? null
      : <div className="potential-winning-container">
        <p className="potential-win-title">{ t('possible win') }</p>
        <p className="potential-win-value">{ `${ potentialWin + ' ' + currency }` }</p>
        { getTaxWinInfo() }
        { getTaxStakeInfo() }
      </div>
  }

  const getPlaceBetProcessingBlock = () => {
    return placeProcess && betResult === null ? <div className="place-bet-process">
      <div className="loader"/>
    </div> : null;
  }

  const getBetResultBlock = () => {
    if (!betResult) {
      return null;
    }

    setTimeout(() => setBetResult(null), resultShowingSeconds);
    const className = betResult === t('bet accepted') ? 'place-bet-results success' : 'place-bet-results fail';

    return <div className={ className }>{ betResult }</div>
  }

  const getBetOptions = () => {
    return betType !== 'System' || currentCoef ?
      optionsEditing
        ? <div className="bet-options">
          <input className="bet-option-input" type="text" ref={ betOption1 } placeholder={ betOptions[0] }
                 defaultValue={ betOptions[0] } maxLength={10}
                 onChange={ event => changeBetValue(event) } min="0" onKeyDown={ Utils.blockInvalidNumbers }
                 onPaste={ e => Utils.blockInvalidPastedNumbers(e) }/>
          <input className="bet-option-input" type="text" ref={ betOption2 } placeholder={ betOptions[1] }
                 defaultValue={ betOptions[1] } maxLength={10}
                 onChange={ event => changeBetValue(event) } min="0" onKeyDown={ Utils.blockInvalidNumbers }
                 onPaste={ e => Utils.blockInvalidPastedNumbers(e) }/>
          <input className="bet-option-input" type="text" ref={ betOption3 } placeholder={ betOptions[2] }
                 defaultValue={ betOptions[2] } maxLength={10}
                 onChange={ event => changeBetValue(event) } min="0" onKeyDown={ Utils.blockInvalidNumbers }
                 onPaste={ e => Utils.blockInvalidPastedNumbers(e) }/>
          <div className="edit-options save" onClick={ () => saveEditedOptions() }>&#10003;</div>
        </div>
        : <div className="bet-options">
          <div className="bet-option" onClick={ () => changeBetValue(betOptions[0]) }>{ betOptions[0] }</div>
          <div className="bet-option" onClick={ () => changeBetValue(betOptions[1]) }>{ betOptions[1] }</div>
          <div className="bet-option" onClick={ () => changeBetValue(betOptions[2]) }>{ betOptions[2] }</div>
          <div className="edit-options" onClick={ () => setOptionsEditing(!optionsEditing) }>&#9998;</div>
        </div>
      : null
  }

  const getMinBetValue = () => {
    const value = Number(minBetValue);
    changeBetValue(value);
  }

  const minusMin = () => {
    if (betValue > minBetValue) {
      const value = Number(betValue) - Number(minBetValue);
      changeBetValue(value);
    }
  }

  const plusMin = () => {
    const value = Number(betValue) + minBetValue;
    changeBetValue(value);
  }

  const doubleUp = () => {
    if (betValue) {
      const value = betValue * 2;
      changeBetValue(value);
    }
  }

  const divideValue = () => {

    if (betValue && betValue && (betValue / 2 >= minBetValue)) {
      const value = betValue / 2;
      changeBetValue(value);
    }
  }

  const getInputBlock = () => {
    return betType === 'Multiple' ? <div className="stake-block">

      <div className="stake-block">
        <div className="stake-button max-button" onClick={ getMinBetValue }>Min</div>
        <div className="stake-button" onClick={ divideValue }>1/2</div>
        <div className="stake-button minus" onClick={ minusMin }>-</div>
        <input className="bet-input" type="text" pattern="^[0-9]" id="stake" placeholder={ t('stake') } maxLength={12}
               value={ betValue } min="0" autoFocus onKeyDown={ Utils.blockInvalidNumbers }
               onPaste={ e => Utils.blockInvalidPastedNumbers(e) }
               onKeyUp={ event => changeBetValue(event.currentTarget.value) }
               onChange={ e => {
                 Utils.correctBetInputValue(e);
                 setBetValue(e.currentTarget.value);
               } }
        />
        <div className="stake-button plus" onClick={ plusMin }>+</div>
        <div className="stake-button" onClick={ doubleUp }>x2</div>
        <div className="stake-button min-button" onClick={ prepareToGetMaxBetValue }>Max</div>
      </div>

    </div> : null;
  }

  const getSystemBetBlock = () => {
    return betType === 'System' && currentCoef ? <div className="system-calculator-block">
      <div className="system-coef">
        <span>{ currentCoef }</span> x
      </div>

      <div className="stake-block">
        <div className="stake-button max-button" onClick={ getMinBetValue }>Min</div>
        <div className="stake-button" onClick={ divideValue }>1/2</div>
        <div className="stake-button minus" onClick={ minusMin }>-</div>
        <input className="bet-input" type="text" pattern="^[0-9]" id="stake" placeholder={ t('stake') } maxLength={12}
               value={ betValue } min="0" autoFocus onKeyDown={ Utils.blockInvalidNumbers }
               onPaste={ e => Utils.blockInvalidPastedNumbers(e) }
               onKeyUp={ event => changeBetValue(event.currentTarget.value) }
               onChange={ e => {
                 Utils.correctBetInputValue(e);
                 setBetValue(e.currentTarget.value);
               } }
        />
        <div className="stake-button plus" onClick={ plusMin }>+</div>
        <div className="stake-button" onClick={ doubleUp }>x2</div>
        <div className="stake-button min-button" onClick={ prepareToGetMaxBetValue }>Max</div>
      </div>

    </div> : null;
  }

  return <div className="max-bet-container">
    { getInputBlock() }
    { getSystemBetBlock() }
    { getPotentialWinningBlock() }
    { getBetOptions() }
    { getPlaceBetBlock() }
    { getPlaceBetProcessingBlock() }
    { getBetResultBlock() }
  </div>
}

export default memo(MaxBet);
